import request from '@/utils/request'

// 查询轮播分页
export function pageMessage (query) {
  return request({
    url: '/com/com-news/page',
    method: 'get',
    params: query
  })
}
// 查询广告所属
export function genusList (query) {
  return request({
    url: '/com/com-Message-genus/list',
    method: 'get',
    params: query
  })
}
// 查询轮播详细
export function getMessage (data) {
  return request({
    url: '/com/com-news/detail',
    method: 'get',
    params: data
  })
}

// 新增轮播
export function addMessage (data) {
  return request({
    url: '/com/com-news/add',
    method: 'post',
    data: data
  })
}

// 修改轮播
export function updateMessage (data) {
  return request({
    url: '/com/com-news/edit',
    method: 'post',
    data: data
  })
}

// 删除轮播
export function delMessage (data) {
  return request({
    url: '/com/com-news/delete',
    method: 'post',
    data: data
  })
}
